const postalContactsProd = {
    search: {
        inputBox: ".search-field #topsearchquery",
        urlParam: "q"
    },
    productClickGrid: {
        productElemWrapper: "#app .products .grid div[data-pid]",
        productPidAttr: "data-pid",
        source: "PRODUCT_CLICK_GRID"
    },
    addToCartFromPDP: {
        addToCartBtnSelector: "#content .add-to-cart, #content .add-to-cart span",
        pidSelector: "#content .add-to-cart",
        productPidAttr: "data-pid",
        source: "ADD_TO_CART_PDP"
    },
    // they don't have the quantity edit button, so we should skip the addToCartFromCartPage.
    // addToCartFromCartPage: {
    //     cartItemWrapper: ".cart-page .card.cart-item",
    //     productPidAttr: "data-pid",
    //     qtySelector: ".cart-page .card select",
    //     source: "ADD_TO_CART_FROM_CART_PAGE"
    // },
    // catPage - handled through UnbxdAnalyticsConf obj
    pageView: {
        delay: 1500,
        events: {
            home: {
                uniqueSelectors: {
                    selectors: ["#oc-images.homepage_only"]
                },
                urlIdentifiers: {
                    urls: ["https://www.postalcontacts.com/"],
                    exactMatch: true
                }
            },
            search: {
                uniqueSelectors: {
                    selectors: [".close-all-search-results"]
                },
                urlIdentifiers: {
                    urls: ["/glasses"],
                    exactMatch: false
                }
            },
            productDisplay: {
                uniqueSelectors: {
                    selectors: [".clearfix #product__img--flow"]
                }
            },
            cart: {
                uniqueSelectors: {
                    selectors: ["#checkout__steps, #cart_replace"]
                },
                urlIdentifiers: {
                    urls: ["/ord/checkout.html"],
                    exactMatch: false
                }
            },
            category: {
                uniqueSelectors: {
                    selectors: [".category-page"]
                }
            },
            order: {
                urlIdentifiers: {
                    urls: ["/process.html"],
                    exactMatch: false,
                }
            }
        }
    }
}


export default postalContactsProd;
